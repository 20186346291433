* {
  box-sizing: border-box;
}

input,
select,
textarea {
  width: 100%;
}

table input,
table select {
  width: auto;
}

input[type='radio'],
input[type='checkbox'] {
  width: auto;
  margin-right: 5px;
}

.checkbox-form {
  padding: 0 1rem;
}

img {
  max-width: 100%;
}

button:focus {
  outline: none !important;
}

.logo-size {
  width: 60%;
}

.ck-content.ck-editor-editable {
  height: 40vh !important;
}

.oauth-bar {
  margin: 10px;
  padding: 10px;
}

.login-button {
  margin: 10px;
}

.login-box {
  border-style: ridge;
}

.log-btn {
  border: none;
}

.oauth-bar .login-button > span {
  border-radius: 8px;
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
}

/* Twitter */
.twitter {
  border: 3px solid #ffffff;
  background: #326ada;
  color: white;
}

/* Google */
.google {
  border: 3px solid #ffffff;
  background: #c4463d;
  color: white;
}

.facebook {
  border: 3px solid #ffffff;
  background: #3b5998;
  color: white;
}

.table tbody tr td:nth-child(odd) {
  background: white;
}

.table tbody tr:hover td {
  background: #ececec;
}

.table tbody tr td {
  border: 1px solid rgba(206, 204, 204, 0.959) !important;
}

.background-hero {
  background-color: #414040b6;
  background-blend-mode: soft-light;
}

.background-hero * {
  color: white !important;
}

nav * {
  color: white;
}

.dropdown-menu.show * {
  color: black !important;
}

.campaign-content {
  word-break: break-word;
}

.campaign-content iframe {
  width: 100%;
}

.campaign-content img {
  max-width: 100%;
  object-fit: cover;
}

.campaign-slider .card-img-top {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

.campaign-slider .card-body {
  height: 180px;
}

.slick-prev:before {
  color: var(--theme-color) !important;
  background-color: #eee;
}
.slick-next:before {
  color: var(--theme-color) !important;
  background-color: #eee;
}

.slick-dots * {
  fill: var(--theme-color);
}

.hero-wrap.hero-wrap-2 {
  height: 100vh !important;
}

.social-media-share-bar > * {
  margin: auto 5px;
}

.ftco-navbar-light {
  z-index: 1000;
}

textarea.form-control {
  border-width: 1px;
  border-color: #2d2d2d;
  font-size: 15px;
}

.search-label {
  width: 100%;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  margin-bottom: 50px;
  text-align: center;
}

.section-title h2 {
  color: #1c1c1c;
  font-weight: 700;
  position: relative;
}

.spad {
  padding-top: 70px;
  padding-bottom: 70px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

.section-title h2:after {
  position: absolute;
  left: 0;
  bottom: -15px;
  right: 0;
  height: 4px;
  width: 80px;
  background: #fdcc24;
  content: '';
  margin: 0 auto;
}

#campaign-individual a,
#campaign-individual a:hover {
  color: var(--theme-color) !important;
}

.ftco-counter .text strong.number > * {
  font-size: 40px;
  color: white;
}

.ftco-counter .text span {
  font-size: 25px;
}

.menu li {
  background: var(--theme-color) !important;
  margin: 5px;
}

.no-touch .menu li a:hover,
.no-touch .menu li a:active,
.no-touch .menu li a:focus {
  border-bottom: 4px solid var(--theme-darker-color) !important;
}

.ftco_navbar.scrolled .nav-link.navbar-brand > span,
.ftco_navbar .nav-link.navbar-brand > img,
#ftco-navbar.admin .nav-link.navbar-brand > span {
  display: none !important;
}

.ftco_navbar.scrolled .nav-link.navbar-brand > img {
  display: block !important;
}

.navbar-brand img {
  margin-top: 10px;
}

#table-toolbar .search-bar {
  background: whitesmoke !important;
  height: 40px !important;
  font-size: 16px;
}

.download-excel-button {
  background: #2aa05f;
  border-radius: 10px;
  height: 40px;
  border-color: #1d6f42;
  outline-color: #1d6f42;
}

.download-excel-button:hover {
  background: #1d6f42;
}

.react-calendar {
  border: 1px solid rgba(199, 198, 198, 0.788) !important;
  padding: 20px;
  width: 95% !important;
  border-radius: 10px;
}

.overflow-box::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.overflow-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.overflow-box::-webkit-scrollbar-thumb {
  background: rgb(180, 180, 180);
}

/* Handle on hover */
.overflow-box::-webkit-scrollbar-thumb:hover {
  background: rgb(128, 126, 126);
}

.object-fit-cover {
  object-fit: cover;
}

a:hover,
button:hover {
  cursor: pointer !important;
}

.swal2-file,
.swal2-input,
.swal2-textarea {
  font-size: 1em;
}

@media (max-width: 768px) and (max-width: 991.98px) {
  .hero-wrap .slider-text h1 span {
    font-size: 41px;
    text-align: left;
  }

  .hero-wrap .slider-text p {
    font-size: 19px !important;
    text-align: left;
  }

  .hero-wrap .slider-text a {
    font-size: 19px;
  }

  .js-fullheight {
    height: 100%;
  }

  .hero-wrap.hero-wrap-2,
  .carousel-inner {
    height: 60vh !important;
  }

  .hero-wrap .slider-text p {
    font-size: 19px !important;
    text-align: left;
  }

  .ftco-counter .text strong.number > * {
    font-size: 35px !important;
  }

  .ftco-navbar-light {
    min-height: 77px;
  }
}

.ftco-footer {
  padding-top: 4em;
  padding-bottom: 2em;
}

@media (max-width: 575.98px) {
  .hero-wrap .slider-text h1 span {
    font-size: 25px;
    text-align: left;
  }

  .hero-wrap .slider-text p {
    font-size: 18px !important;
    text-align: left;
  }

  .hero-wrap .slider-text a {
    font-size: 19px;
  }

  .hero-wrap.hero-wrap-2,
  .carousel-inner {
    height: 90vh !important;
  }

  .hero-wrap * {
    text-align: left !important;
  }

  .hero-wrap p {
    margin-bottom: 40px;
  }

  .one-forth {
    align-items: center !important;
  }

  .one-forth > * {
    min-width: 100% !important;
  }

  .ftco-navbar-light {
    min-height: unset;
  }
}

.ftco-section {
  padding-bottom: 2em !important;
}

.input-search input {
  background-color: whitesmoke !important;
}

.filters {
  display: flex;
  justify-content: center;
  width: 95%;
}

.filters input,
.filters select {
  background-color: #e4e8f0 !important;
  outline: 0 !important;
  border: 0 !important;
  /* font-family: 'Noto Sans JP', sans-serif; */
  /* font-size: 85%; */
  border-radius: 10px;
  height: 100%;
  padding: 20px;
}

.filters .button,
.filters .button:hover {
  background-color: var(--theme-color);
  color: #ffffff;
  height: 100%;
  width: 90%;
  border-radius: 5px;
}

.filters .button:hover {
  background-color: var(--theme-darker-color);
}

.search-bar,
.search-bar input,
.search-bar button {
  height: 40px !important;
}

.swal-text {
  text-align: center;
}

.product-img {
  width: 100%;
}

.product-img img {
  float: left;
  max-width: 100%;
  max-height: 100px;
  object-fit: cover;
}

/*---------------------
  Header
-----------------------*/

.header-top {
  background: #f5f5f5;
}

.header-top-left {
  padding: 13px 0 22px;
}

.header-top-left ul li {
  font-size: 14px;
  color: #1c1c1c;
  display: inline-block;
  margin-right: 45px;
  position: relative;
}

.header-top-left ul li:after {
  position: absolute;
  right: -25px;
  top: 1px;
  height: 20px;
  width: 1px;
  background: #000000;
  opacity: 0.1;
  content: '';
}

.header-top-left ul li:last-child {
  margin-right: 0;
}

.header-top-left ul li:last-child:after {
  display: none;
}

.header-top-left ul li i {
  color: #16161F;
  margin-right: 5px;
}

.header-top-right {
  text-align: right;
  padding: 19px 0 22px;
}

.header-top-right-social {
  position: relative;
  display: inline-block;
  margin-right: 35px;
}

.header-top-right-social:after {
  position: absolute;
  right: -20px;
  top: 1px;
  height: 20px;
  width: 1px;
  background: #000000;
  opacity: 0.1;
  content: '';
}

.header-top-right-social a {
  font-size: 14px;
  display: inline-block;
  color: #1c1c1c;
  margin-right: 20px;
}

.header-top-right-social a:last-child {
  margin-right: 0;
}

.header-top-right-language {
  position: relative;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}

.header-top-right-language:hover ul {
  top: 23px;
  opacity: 1;
  visibility: visible;
}

.header-top-right-language:after {
  position: absolute;
  right: -21px;
  top: 1px;
  height: 20px;
  width: 1px;
  background: #000000;
  opacity: 0.1;
  content: '';
}

.header-top-right-language img {
  margin-right: 6px;
}

.header-top-right-language div {
  font-size: 14px;
  color: #1c1c1c;
  display: inline-block;
  margin-right: 4px;
}

.header-top-right-language span {
  font-size: 14px;
  color: #1c1c1c;
  position: relative;
  top: 2px;
}

.header-top-right-language ul {
  background: #222222;
  width: 100px;
  text-align: left;
  padding: 5px 0;
  position: absolute;
  left: 0;
  top: 43px;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.header-top-right-language ul li {
  list-style: none;
}

.header-top-right-language ul li a {
  font-size: 14px;
  color: #ffffff;
  padding: 5px 10px;
}

.header-top-right-auth {
  display: inline-block;
}

.header-top-right-auth a {
  display: block;
  font-size: 14px;
  color: #1c1c1c;
}

.header-top-right-auth a i {
  margin-right: 6px;
}

.header-logo {
  padding: 15px 0;
}

.header-logo a {
  display: inline-block;
}

.header-menu {
  padding: 19px 0 5px 0;
}

.header-menu ul li {
  list-style: none;
  display: inline-block;
  margin: 0px 5px 0px 45px;
  position: relative;
}

.header-menu ul li .header-menu-dropdown {
  position: absolute;
  left: 0;
  top: 50px;
  background: #222222;
  width: 180px;
  z-index: 9;
  padding: 5px 0;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  opacity: 0;
  visibility: hidden;
}

.header-menu ul li .header-menu-dropdown li {
  margin-right: 0;
  display: block;
}

.header-menu ul li .header-menu-dropdown li:hover > a {
  color: #fdcc24;
}

.header-menu ul li .header-menu-dropdown li a {
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 400;
  padding: 5px 15px;
}

.header-menu ul li.active a {
  color: #fdcc24;
}

.header-menu ul li:hover .header-menu-dropdown {
  top: 30px;
  opacity: 1;
  visibility: visible;
}

.header-menu ul li:hover > a {
  color: #fdcc24;
}

.header-menu ul li:last-child {
  margin-right: 0;
}

.header-menu ul li a {
  font-size: 14px;
  color: #16161F;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2px;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  padding: 5px 0;
  display: block;
}

.header-menu ul li a i {
  font-size: 18px;
  color: #1c1c1c;
}

.header-menu ul li a span {
  height: 13px;
  width: 13px;
  background: #fdcc24;
  font-size: 10px;
  color: #ffffff;
  line-height: 13px;
  text-align: center;
  font-weight: 700;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  right: -10px;
}

.header-cart {
  padding: 23px 0 5px 0;
}

.header-cart ul {
  display: inline-block;
  margin-right: 25px;
  padding: 0;
}

.header-cart ul li {
  list-style: none;
  display: inline-block;
  margin-right: 30px;
}

.header-cart ul li:last-child {
  margin-right: 0;
}

.header-cart ul li a {
  position: relative;
}

.header-cart .header-cart-price {
  font-size: 15px;
  color: #6f6f6f;
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
}

.header-cart .header-cart-price span {
  color: #16161F;
  font-weight: 700;
}

.humberger-menu-wrapper {
  display: none;
}

.humberger-open {
  display: none;
}

/*---------------------
  Hero
-----------------------*/

.hero {
  padding-bottom: 50px;
}

.hero.hero-normal {
  padding-bottom: 30px;
}

.hero.hero-normal .hero-categories {
  position: relative;
}

.hero.hero-normal .hero-categories ul {
  display: none;
  position: absolute;
  left: 0;
  top: 46px;
  width: 100%;
  z-index: 9;
  background: #ffffff;
}

.hero.hero-normal .hero-search {
  margin-bottom: 0;
}

.hero-categories-all button {
  background: #fdcc24;
  position: relative;
  padding: 5px 40px 5px 40px;
  cursor: pointer;
  color: black;
  font-size: 18px;
  font-weight: 700;
  border: none;
}

.dropdown-menu {
  position: absolute;
  transform: translate3d(15px, 48px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.hero-categories-all i {
  font-size: 16px;
  color: black;
  margin-right: 35px;
}

.hero-search {
  overflow: hidden;
  margin-bottom: 30px;
}

.hero-search-form {
  width: 840px;
  height: 50px;
  border: 1px solid #ebebeb;
  position: relative;
  float: left;
}

.hero-search-form form .hero-search-categories {
  width: 30%;
  float: left;
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 700;
  padding-left: 18px;
  padding-top: 11px;
  position: relative;
}

.hero-search-form form .hero-search-categories:after {
  position: absolute;
  right: 0;
  top: 14px;
  height: 20px;
  width: 1px;
  background: #000000;
  opacity: 0.1;
  content: '';
}

.hero-search-form form .hero-search-categories span {
  position: absolute;
  right: 14px;
  top: 14px;
}

.hero-search-form form input {
  width: 90%;
  border: none;
  height: 48px;
  font-size: 16px;
  color: #b2b2b2;
  padding-left: 20px;
}

.hero-search-form form input::placeholder {
  color: #b2b2b2;
}

.hero-search-form form button {
  position: absolute;
  right: 0;
  top: -1px;
  height: 50px;
}

.site-btn {
  font-size: 14px;
  color: black;
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
  padding: 13px 30px 12px;
  background: #e7e5e5;
  border: none;
}

.hero-search-phone {
  float: right;
}

.hero-search-phone-icon {
  font-size: 18px;
  color: #fdcc24;
  height: 50px;
  width: 50px;
  background: #f5f5f5;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
}

.hero-search-phone-text {
  overflow: hidden;
}

.hero-search-phone-text h5 {
  color: #1c1c1c;
  font-weight: 700;
  margin: 10px 0;
}

.hero-search-phone-text span {
  font-size: 14px;
  color: #6f6f6f;
}

.hero-item {
  height: 431px;
  display: flex;
  align-items: center;
  padding-left: 75px;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-image: url('../src/assets/images/home.jpg');
}

.set-bg1 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.set-bg6 {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}

.hero-text {
  margin-top: 10rem;
}

.hero-text h2 {
  font-size: 46px;
  color: #16161F;
  line-height: 52px;
  font-weight: 700;
  margin: 10px 0;
}

.hero-text p {
  margin-bottom: 35px;
}

.filter-item {
  padding-top: 85px;
  padding-bottom: 20px;
}

.filter-sort {
  margin-bottom: 15px;
}

.filter-sort span {
  font-size: 16px;
  color: #6f6f6f;
  display: inline-block;
}

.filter-sort .nice-select {
  background-color: #fff;
  border-radius: 0;
  border: none;
  display: inline-block;
  float: none;
  height: 0;
  line-height: 0;
  padding-left: 18px;
  padding-right: 30px;
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 700;
  cursor: pointer;
}

.filter-sort .nice-select span {
  color: #1c1c1c;
}

.filter-sort .nice-select:after {
  border-bottom: 1.5px solid #1c1c1c;
  border-right: 1.5px solid #1c1c1c;
  height: 8px;
  margin-top: 0;
  right: 16px;
  width: 8px;
  top: -5px;
}

.filter-sort .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.filter-sort .nice-select .list {
  border-radius: 0;
  margin-top: 0;
  top: 15px;
}

.filter-sort .nice-select .option {
  line-height: 30px;
  min-height: 30px;
}

.filter-found {
  text-align: center;
  margin-bottom: 15px;
}

.filter-found h6 {
  font-size: 16px;
  color: #b2b2b2;
}

.filter-found h6 span {
  color: #1c1c1c;
  font-weight: 700;
  margin-right: 5px;
}

.filter-option {
  text-align: right;
  margin-bottom: 15px;
}

.filter-option span {
  font-size: 24px;
  color: #b2b2b2;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.filter-option span:last-child {
  margin: 0;
}

.filter-option span:hover {
  color: #7fad39;
}

/*---------------------
  Categories
-----------------------*/
.categories-size {
  padding: 0 1rem;
}

.categories-item {
  height: 270px;
  position: relative;
}

.categories-item h5 {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 20px;
  bottom: 20px;
  text-align: center;
}

.categories-item h5 span {
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 12px 0 10px;
  background: #ffffff;
  display: block;
}

.categories-slider .col-lg-3 {
  max-width: 100%;
}

.categories-slider.owl-carousel .owl-nav button {
  font-size: 18px;
  color: #1c1c1c;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  border: 1px solid #ebebeb;
  position: absolute;
  left: -35px;
  top: 50%;
  -webkit-transform: translateY(-35px);
  background: #ffffff;
}

.categories-slider.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: -35px;
}

/*---------------------
  Featured
-----------------------*/

.featured {
  padding-top: 80px;
  padding-bottom: 40px;
}

.featured-item {
  margin-bottom: 50px;
}

.featured-item:hover .featured-item-pic .featured-item-pic-hover {
  bottom: 20px;
}

.featured-item-pic {
  height: 270px;
  position: relative;
  overflow: hidden;
  background-position: center center;
}

.featured-item-pic-hover {
  position: absolute;
  left: 0;
  bottom: -80px;
  width: 100%;
  text-align: center;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.featured-item-pic-hover li {
  list-style: none;
  display: inline-block;
  margin-right: 6px;
}

.featured-item-pic-hover li:last-child {
  margin-right: 0;
}

.featured-item-pic-hover li:hover a {
  background: #fdcc24;
  border-color: #fdcc24;
}

.featured-item-pic-hover li:hover a i {
  color: #ffffff;
  transform: rotate(360deg);
}

.featured-item-pic-hover li a {
  font-size: 16px;
  color: #1c1c1c;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.featured-item-pic-hover li a i {
  position: relative;
  transform: rotate(0);
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.featured-item-text {
  text-align: center;
  padding-top: 15px;
}

.featured-item-text h6 {
  margin-bottom: 10px;
}

.featured-item-text h6 a {
  color: #16161F;
}

.featured-item-text h5 {
  color: #16161F;
  font-weight: 700;
}

/*---------------------
  Latest Product
-----------------------*/

.latest-product {
  padding-top: 80px;
  padding-bottom: 0;
}

.latest-product-text h4 {
  font-size: 20px;
  font-weight: 700;
  color: #1c1c1c;
  margin-bottom: 35px;
}

.latest-product-slider.owl-carousel .owl-nav {
  position: absolute;
  right: 20px;
  top: -75px;
}

.latest-product-slider.owl-carousel .owl-nav button {
  height: 30px;
  width: 30px;
  background: #f3f6fa;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  color: #636363;
  margin-right: 10px;
  line-height: 30px;
  text-align: center;
}

.latest-product-slider.owl-carousel .owl-nav button span {
  font-weight: 700;
}

.latest-product-slider.owl-carousel .owl-nav button:last-child {
  margin-right: 0;
}
.latest-product-item h6{
  font-size: 16px;
  line-height: 1;
  margin: 0;
}

.latest-product-item {
  margin-bottom: 10px;
  overflow: hidden;
  display: block;
}

.latest-product-item-pic {
  float: left;
  margin-right: 26px;
  width: 100px;
  height: 100px;
  text-align: center;
}

.latest-product-item-pic img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;

  margin-left: 50%;
  transform: translateX(-50%);
}

.latest-product-item-text {
  overflow: hidden;
  padding-top: 10px;
}

.latest-product-item-text h6 {
  font-size: 0.8rem;
  color: #b2b2b2;
  display: block;
  margin-bottom: 4px;
  display: inline-block;
  padding: 10px;
  color: black;
  font-weight: bold;
  background: #fdcc24;
  letter-spacing: 1px;
}

.latest-product-item-text span {
  font-size: 14px;
  display: block;
  color: #16161F;
}

.product-out-stock {
  background: #d4d9e0;
  width: 110px;
}

.product-out-details {
  background: #d4d9e0;
  width: 120px;
}
/*---------------------
  Breadcrumb
-----------------------*/

.breadcrumb-section {
  display: flex;
  align-items: center;
  padding: 20px 0 5px;
  line-height: 1;
}

.breadcrumb-text h2 {
  font-size: 46px;
  color: black;
  font-weight: 700;
  margin-bottom: 1px;
}

.breadcrumb-option span {
  display: inline-block;
  font-size: 20px;
  color: black;
  position: relative;
}

/*---------------------
  Shop Grid
-----------------------*/

.product {
  padding-top: 40px;
  padding-bottom: 40px;
}

.product-discount {
  padding-bottom: 50px;
}

.product-discount-title {
  text-align: left !important;
  margin-bottom: 65px;
}

.product-discount-title h2 {
  display: inline-block;
}

.product-discount-title h2:after {
  margin: 0;
  width: 100%;
}

.product-discount-item:hover .product-discount-item-pic .product-item-pic-hover {
  bottom: 20px;
}

.product-discount-item-pic {
  height: 270px;
  position: relative;
  overflow: hidden;
}

.product-discount-item-pic .product-discount-percent {
  height: 45px;
  width: 45px;
  background: #dd2222;
  border-radius: 50%;
  font-size: 14px;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
  position: absolute;
  left: 15px;
  top: 15px;
}

.product-item-pic-hover {
  position: absolute;
  left: 0;
  bottom: -80px;
  width: 100%;
  text-align: center;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
  padding: 0;
}

.product-item-pic-hover li {
  list-style: none;
  display: inline-block;
  margin-right: 6px;
}

.product-item-pic-hover li:last-child {
  margin-right: 0;
}

.product-item-pic-hover li:hover a {
  background: #fdcc24;
  border-color: #fdcc24;
}

.product-item-pic-hover li:hover button {
  background: #fdcc24;
  border-color: #fdcc24;
}

.product-item-pic-hover li:focus button {
  outline: 0px dotted;
  -webkit-tap-highlight-color: transparent;
}

.product-item-pic-hover li:hover a i {
  color: #ffffff;
  transform: rotate(360deg);
}

.product-item-pic-hover li:hover button i {
  color: #ffffff;
  transform: rotate(360deg);
}

.product-item-pic-hover li a {
  font-size: 16px;
  color: #1c1c1c;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.product-item-pic-hover li button {
  font-size: 16px;
  color: #1c1c1c;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.product-item-pic-hover li a i {
  position: relative;
  transform: rotate(0);
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.product-discount-item-text {
  text-align: center;
  padding-top: 20px;
}

.product-discount-item-text span {
  font-size: 14px;
  color: #b2b2b2;
  display: block;
  margin-bottom: 4px;
}

.product-discount-item-text h5 {
  margin-bottom: 6px;
}

.product-discount-item-text h5 a {
  color: #1c1c1c;
}

.product-discount-item-text .product-item-price {
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 700;
}

.product-discount-item-text .product-item-price span {
  display: inline-block;
  font-weight: 400;
  text-decoration: line-through;
  margin-left: 10px;
}

.product-discount-slider .col-lg-4 {
  max-width: 100%;
}

.product-discount-slider.owl-carousel .owl-dots {
  text-align: center;
  margin-top: 30px;
}

.product-discount-slider.owl-carousel .owl-dots button {
  height: 12px;
  width: 12px;
  border: 1px solid #b2b2b2;
  border-radius: 50%;
  margin-right: 12px;
}

.product-discount-slider.owl-carousel .owl-dots button.active {
  background: #888888;
  border-color: #6f6f6f;
}

.product-discount-slider.owl-carousel .owl-dots button:last-child {
  margin-right: 0;
}

.filter-sort {
  margin-bottom: 15px;
}

.filter-sort span {
  font-size: 16px;
  color: #6f6f6f;
  display: inline-block;
}

.filter-sort .nice-select {
  background-color: #fff;
  border-radius: 0;
  border: none;
  display: inline-block;
  float: none;
  height: 0;
  line-height: 0;
  padding-left: 18px;
  padding-right: 30px;
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 700;
  cursor: pointer;
}

.filter-sort .nice-select span {
  color: #1c1c1c;
}

.filter-sort .nice-select:after {
  border-bottom: 1.5px solid #1c1c1c;
  border-right: 1.5px solid #1c1c1c;
  height: 8px;
  margin-top: 0;
  right: 16px;
  width: 8px;
  top: -5px;
}

.filter-sort .nice-select.open .list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
}

.filter-sort .nice-select .list {
  border-radius: 0;
  margin-top: 0;
  top: 15px;
}

.filter-sort .nice-select .option {
  line-height: 30px;
  min-height: 30px;
}

.filter-found {
  text-align: center;
  margin-bottom: 15px;
}

.filter-found h6 {
  font-size: 16px;
  color: #b2b2b2;
}

.filter-found h6 span {
  color: #1c1c1c;
  font-weight: 700;
  margin-right: 5px;
}

.filter-option {
  text-align: right;
  margin-bottom: 15px;
}

.filter-option span {
  font-size: 24px;
  color: #b2b2b2;
  margin-right: 10px;
  cursor: pointer;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.filter-option span:last-child {
  margin: 0;
}

.filter-option span:hover {
  color: #fdcc24;
}

.product-item {
  margin: 0 50px 30px 23px;
}

.product-item:hover .product-item-pic .product-item-pic-hover {
  bottom: 20px;
  padding: 0;
}

.product-item-img-container {
  width: 100%;
}

.product-item-img-container img {
  float: left;
  max-width: 90%;
  max-height: 255px;
  object-fit: cover;
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 5%;
}

.product-item-pic {
  height: 250px;
  position: relative;
  overflow: hidden;
}

.product-item-pic-hover li {
  list-style: none;
  display: inline-block;
  margin: 10px;
}

.product-item-pic-hover li:last-child {
  margin-right: 0;
}

.product-item-pic-hover li:hover a {
  background: #fdcc24;
  border-color: #fdcc24;
}

.product-item-pic-hover li:hover a i {
  color: #ffffff;
  transform: rotate(360deg);
}

.product-item-pic-hover li a {
  font-size: 16px;
  color: #1c1c1c;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #ebebeb;
  background: #ffffff;
  display: block;
  border-radius: 50%;
  -webkit-transition: all, 0.5s;
  -moz-transition: all, 0.5s;
  -ms-transition: all, 0.5s;
  -o-transition: all, 0.5s;
  transition: all, 0.5s;
}

.product-item-pic-hover li a i {
  position: relative;
  transform: rotate(0);
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.product-item-text {
  border-top: 2px solid;
  text-align: left;
  padding-top: 8px;
}

.product-item-text h6 {
  font-size: 0.9rem;
  margin-bottom: 1px;
}

.product-item-text h4 {
  color: #16161F;
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 1px;
}

.product-item-text span {
  font-size: 0.8rem;
  color: #b2b2b2;
  display: block;
  margin-bottom: 4px;
  display: inline-block;
  padding: 10px;
  color: black;
  font-weight: bold;
  background: #fdcc24;
  letter-spacing: 1px;
}

.product-pagination,
.blog-pagination {
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.product-pagination a,
.blog-pagination a {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #b2b2b2;
  font-size: 14px;
  color: black;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
}

.product-pagination a:hover,
.blog-pagination a:hover {
  background: #fdcc24;
  border-color: #fdcc24;
  color: #ffffff;
}

.product-pagination a:last-child,
.blog-pagination a:last-child {
  margin-right: 0;
}

/*---------------------
  Slider
-----------------------*/

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #fdcc24;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #fdcc24;
  cursor: pointer;
}

/*---------------------
  Sidebar
-----------------------*/

.sidebar-item {
  margin-bottom: 35px;
}

.sidebar-item.sidebar-item-color--option {
  overflow: hidden;
}

.sidebar-item h4 {
  color: #1c1c1c;
  font-weight: 700;
  margin-bottom: 25px;
}

.sidebar-item ul li {
  list-style: none;
}

.sidebar-item ul li a {
  font-size: 16px;
  color: #1c1c1c;
  line-height: 39px;
  display: block;
}

.sidebar-item .latest-product-text {
  position: relative;
}

.sidebar-item .latest-product-text h4 {
  margin-bottom: 45px;
}

.sidebar-item .latest-product-text .owl-carousel .owl-nav {
  right: 0;
}

.price-range-wrap .range-slider {
  margin-top: 20px;
}

.price-range-wrap .range-slider .price-input {
  position: relative;
}

.price-range-wrap .range-slider .price-input:after {
  position: absolute;
  left: 38px;
  top: 13px;
  height: 1px;
  width: 5px;
  background: #dd2222;
  content: '';
}

.price-range-wrap .range-slider .price-input input {
  font-size: 16px;
  color: #dd2222;
  font-weight: 700;
  max-width: 20%;
  border: none;
  display: inline-block;
}

.price-range-wrap .price-range {
  border-radius: 0;
}

.price-range-wrap .price-range.ui-widget-content {
  border: none;
  background: #ebebeb;
  height: 5px;
}

.price-range-wrap .price-range.ui-widget-content .ui-slider-handle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background: #ffffff;
  border: none;
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
}

.price-range-wrap .price-range .ui-slider-range {
  background: #dd2222;
  border-radius: 0;
}

.price-range-wrap .price-range .ui-slider-range.ui-corner-all.ui-widget-header:last-child {
  background: #dd2222;
}

.sidebar-item-color {
  float: left;
  width: 40%;
}

.sidebar-item-color.sidebar-item-color--white label:after {
  border: 2px solid #333333;
  background: transparent;
}

.sidebar-item-color.sidebar-item-color--gray label:after {
  background: #e9a625;
}

.sidebar-item-color.sidebar-item-color--red label:after {
  background: #d62d2d;
}

.sidebar-item-color.sidebar-item-color--black label:after {
  background: #16161F;
}

.sidebar-item-color.sidebar-item-color--blue label:after {
  background: #249bc8;
}

.sidebar-item-color.sidebar-item-color--green label:after {
  background: #3cc032;
}

.sidebar-item-color label {
  font-size: 16px;
  color: #333333;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
}

.sidebar-item-color label input {
  position: absolute;
  visibility: hidden;
}

.sidebar-item-color label:after {
  position: absolute;
  left: 0;
  top: 5px;
  height: 14px;
  width: 14px;
  background: #222;
  content: '';
  border-radius: 50%;
}

.sidebar-item-size {
  display: inline-block;
  margin-right: 16px;
  margin-bottom: 10px;
}

.sidebar-item-size label {
  font-size: 12px;
  color: #6f6f6f;
  display: inline-block;
  padding: 8px 25px 6px;
  background: #f5f5f5;
  cursor: pointer;
  margin-bottom: 0;
}

.sidebar-item-size label input {
  position: absolute;
  visibility: hidden;
}

/*---------------------
  Shop Cart
-----------------------*/

.shoping-cart {
  padding-top: 80px;
  padding-bottom: 80px;
}

.shoping-cart-table {
  margin-bottom: 30px;
}

.shoping-cart-table table {
  width: 100%;
}

.shoping-cart-table table thead tr {
  border-bottom: 1px solid #ebebeb;
}

.shoping-cart-table table thead th {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #1c1c1c;
  padding: 3px 0px;
  background-color: rgb(227, 240, 248);
}

.shoping-cart-table table tbody tr td {
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid #ebebeb;
}

.shoping-cart-table table tbody tr td.shoping-cart-item {
  width: 150px;
  text-align: left;
  height: 150px;
}

.shoping-cart-table table tbody tr td.shoping-cart-item img {
  display: inline-block;
  margin: 0px 25px;
}

.shoping-cart-table table tbody tr td.shoping-cart-item h5 {
  color: #1c1c1c;
  font-weight: 700;
  display: flex;
  padding: 30px 0;
  justify-content: space-evenly;
}

.shoping-cart-table table tbody tr td.shoping-cart-price {
  font-size: 18px;
  color: #0066FF;
  font-weight: 700;
  width: 100px;
  padding: 0 20px;
}

.shoping-cart-table table tbody tr td.shoping-cart-total {
  font-size: 18px;
  color: #1c1c1c;
  font-weight: 700;
  width: 110px;
}

.shoping-cart-table table tbody tr td.shoping-cart-item-close {
  text-align: center;
}

.shoping-cart-table table tbody tr td.shoping-cart-item-close button {
  font-size: 20px;
  color: #1c1c1c;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity {
  width: 190px;
  padding: 0 10px;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity .pro-qty {
  width: 120px;
  height: 40px;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity .pro-qty button {
  width: 25px;
  height: 38px;
  border: none;
  background-color: transparent;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity .pro-qty input {
  color: #1c1c1c;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity .pro-qty input::placeholder {
  color: #1c1c1c;
}

.shoping-cart-table table tbody tr td.shoping-cart-quantity .pro-qty .qtybtn {
  width: 15px;
}

.description-item {
  padding-left: 10%;
}

/* wish list styles */

.wish-list-table {
  margin-bottom: 30px;
}

.wish-list-table table {
  width: 100%;
}

.wish-list-table table thead tr {
  border-bottom: 1px solid #ebebeb;
}

.wish-list-table table thead th {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: bold;
  color: #1c1c1c;
  padding: 3px 0px;
  background-color: rgb(227, 240, 248);
}

.wish-list-table table tbody tr td {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
}

.wish-list-table table tbody tr td.wish-list-item {
  text-align: left;
  height: 150px;
  width: 150px;
}

.wish-list-table table tbody tr td.wish-list-item img {
  display: inline-block;
  margin: 0 25px;
}

.wish-list-table table tbody tr td.wish-list-item h5 {
  color: #1c1c1c;
  display: flex;
  padding: 30px 0;
  justify-content: space-evenly;
}

.wish-list-table table tbody tr td.wish-list-price {
  font-size: 18px;
  color: #0066FF;
  font-weight: 700;
  width: 150px;
  padding: 0 5px;
}

.wish-list-table table tbody tr td.wish-list-stock {
  font-size: 18px;
  padding: 0 20px;
}

.wish-list-table table tbody tr td.wish-list-item-close {
  text-align: center;
}

.wish-list-table table tbody tr td.wish-list-item-close button {
  font-size: 20px;
  color: #1c1c1c;
  cursor: pointer;
  -webkit-appearance: none;
  border: none;
}

.wish-list-btn {
  display: flex;
  justify-content: flex-end;
}

.primary-btn.cart-btn span {
  font-size: 14px;
}

.primary-btn.cart-btn.cart-btn-right {
  float: right;
}

.shoping-discount {
  margin-top: 45px;
}

.shoping-discount h5 {
  font-size: 20px;
  color: #1c1c1c;
  font-weight: 700;
  margin-bottom: 25px;
}

.shoping-discount form input {
  width: 255px;
  height: 46px;
  border: 1px solid #cccccc;
  font-size: 16px;
  color: #b2b2b2;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
}

.shoping-discount form input::placeholder {
  color: #b2b2b2;
}

.shoping-discount form button {
  padding: 15px 30px 11px;
  font-size: 12px;
  letter-spacing: 4px;
  background: #6f6f6f;
}

.shoping-checkout {
  background: #e4e3e3;
  padding: 30px;
  padding-top: 20px;
  margin-top: 50px;
  border-style: ridge;
}

.shoping-checkout h5 {
  color: #1c1c1c;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 28px;
}

.shoping-checkout ul {
  margin-bottom: 28px;
}

.shoping-checkout ul li {
  font-size: 16px;
  color: #1c1c1c;
  font-weight: 700;
  list-style: none;
  overflow: hidden;
  border-bottom: 2px solid black;
  padding-bottom: 13px;
  margin-bottom: 18px;
}

.shoping-checkout ul li:last-child {
  padding-bottom: 0;
  border-bottom: none;
  margin-bottom: 0;
}

.shoping-checkout ul li span {
  font-size: 18px;
  color: #326ada;
  float: right;
}

.shoping-checkout .primary-btn {
  display: block;
  text-align: center;
}

.pro-qty {
  width: 140px;
  height: 50px;
  display: inline-block;
  position: relative;
  text-align: center;
  background: #f5f5f5;
  margin-bottom: 5px;
}

.pro-qty input {
  height: 100%;
  width: 100%;
  font-size: 16px;
  color: #6f6f6f;
  width: 50px;
  border: none;
  background: #f5f5f5;
  text-align: center;
}

.pro-qty .qtybtn {
  width: 35px;
  font-size: 16px;
  color: #6f6f6f;
  cursor: pointer;
  display: inline-block;
}

.plus {
  display: inline-block;
  margin: 0 12px;
}

/*---------------------
  Shop Details
-----------------------*/

.section-product{
  border-style: ridge;
  padding: 1% 2%;
}

.product-details {
  padding-top: 80px;
}

.product-details-pic-item {
  margin-bottom: 20px;
  width: 100%;
}

.product-details-pic-item img {
  float: left;
  max-width: 100%;
  max-height: 350px;
  object-fit: cover;
  margin-left: 50%;
  transform: translateX(-50%);
}

.product-details-text h3 {
  color: #16161F;
  font-weight: 600;
  margin-top: 20px;
  line-height: 1;
}

.product-details-text .product-details-rating {
  font-size: 14px;
  margin-bottom: 12px;
}

.product-details-text .product-details-rating i {
  margin-right: -2px;
  color: #edbb0e;
}

.product-details-text .product-details-rating span {
  color: #888888;
  text-decoration: underline;
  margin-left: 4px;
}

.product-details-text .product-details-price {
  font-size: 27px;
  color: #0066FF;
  font-weight: bold;
  margin-bottom: 15px;
}

.product-details-text p {
  margin-bottom: 15px;
  text-align: justify;
}

.product-details-text .primary-btn {
  padding: 14px 28px 11px;
  margin-right: 6px;
  margin-bottom: 5px;
  border: none;
}
.product-details-text .primary-btn a {
  color: white;
}

.product-details-text .heart-icon {
  display: inline-block;
  font-size: 16px;
  color: #000000;
  padding: 12px 16px 10px;
  background: #f5f5f5;
  border: none;
}

.product-details-text ul {
  border-top: 1px solid #ebebeb;
  padding-top: 40px;
  margin-top: 50px;
}

.product-details-text ul li {
  font-size: 16px;
  color: #1c1c1c;
  list-style: none;
  line-height: 36px;
}

.product-details-text ul li b {
  font-weight: 700;
  width: 170px;
  display: inline-block;
}

.product-details-text ul li span samp {
  color: #dd2222;
}

.product-details-text ul li .share {
  display: inline-block;
}

.product-details-text ul li .share a {
  display: inline-block;
  font-size: 15px;
  color: #1c1c1c;
  margin-right: 25px;
}

.product-details-text ul li .share a:last-child {
  margin-right: 0;
}

.product-details-quantity {
  display: inline-block;
  margin-right: 6px;
}

.design-review a{
  font-weight: 100;
  font-size: 25px;
}

.pro-qty button {
  width: 40px;
  height: 50px;
  border: none;
  background-color: transparent;
}

.pro-qty input {
  color: #000000;
}

.pro-qty .qtybtn {
  width: 35px;
  font-size: 16px;
  color: #6f6f6f;
  cursor: pointer;
  display: inline-block;
}

.product-details-tab {
  padding-top: 50px;
}

.product-details-tab .nav-tabs {
  border-bottom: none;
  justify-content: center;
  position: relative;
}

.product-details-tab .nav-tabs:before {
  position: absolute;
  left: 0;
  top: 22px;
  height: 1px;
  width: 460px;
  background: #ebebeb;
  content: '';
}

.product-details-tab .nav-tabs:after {
  position: absolute;
  right: 0;
  top: 22px;
  height: 1px;
  width: 460px;
  background: #ebebeb;
  content: '';
}

.product-details-tab .nav-tabs li {
  margin-bottom: 0;
  margin-right: 65px;
}

.product-details-tab .nav-tabs li:last-child {
  margin-right: 0;
}

.product-details-tab .nav-tabs li a {
  font-size: 24px;
  color: #999999;
  font-weight: 100;
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
}

.product-details-tab .product-details-tab-desc {
  padding-top: 44px;
}

.product-details-tab .product-details-tab-desc h6 {
  font-weight: 700;
  color: #333333;
  margin-bottom: 26px;
}

.product-details-tab .product-details-tab-desc p {
  color: #666666;
}

/*---------------------
  Shop Details
-----------------------*/

.category-button {
  font-size: 16px;
  color: #1c1c1c;
  line-height: 39px;
  display: block;
  border: none;
  background-color: transparent;
}

/*---------------------
  Footer
-----------------------*/

.footer {
  background: #f3f6fa;
  padding: 70px 40px 20px 70px;
}

.footer-about {
  margin-bottom: 20px;
}

.footer-about ul {
  padding: 0;
}

.footer-about ul li {
  font-size: 14px;
  color: #1c1c1c;
  line-height: 30px;
  list-style: none;
  padding: 0;
}

.footer-about-logo a {
  display: inline-block;
}

.footer-widget {
  margin-bottom: 30px;
  overflow: hidden;
}

.footer-about-logo span {
  color: black;
  text-decoration: underline;
  font-size: 1rem;
  text-transform: uppercase;
}

.footer-widget h6 {
  color: black;
  text-decoration: underline;
  font-size: 1rem;
  text-transform: uppercase;
}

.footer-widget span {
  font-size: .8rem;
}

.footer-widget ul {
  width: 50%;
  float: left;
  padding: 0;
}

.footer-widget ul li {
  list-style: none;
}

.footer-widget ul li a {
  color: #1c1c1c;
  font-size: 14px;
  line-height: 32px;
}

.footer-widget p {
  font-size: 14px;
  color: #1c1c1c;
  margin-bottom: 30px;
}

.footer-widget form {
  position: relative;
  margin-bottom: 30px;
}

.footer-widget form input {
  width: 100%;
  font-size: 16px;
  padding-left: 20px;
  color: #1c1c1c;
  height: 46px;
  border: 1px solid #ededed;
}

.footer-widget form input::placeholder {
  color: #1c1c1c;
}

.footer-widget form button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 26px;
  height: 100%;
}

.footer-widget .footer-widget-social a {
  display: inline-block;
  height: 41px;
  width: 41px;
  font-size: 16px;
  color: #404040;
  line-height: 38px;
  text-align: center;
  -webkit-transition: all, 0.3s;
  -moz-transition: all, 0.3s;
  -ms-transition: all, 0.3s;
  -o-transition: all, 0.3s;
  transition: all, 0.3s;
  margin-right: 10px;
}

.footer-widget .footer-widget-social a:last-child {
  margin-right: 0;
}

.footer-widget .footer-widget-social a:hover {
  color: #fdcc24;
}

.footer-widget-social {
  margin-top: 25px;
}

.footer-copyright {
  border-top: 1px solid #ebebeb;
  padding: 15px 0;
  overflow: hidden;
  margin-top: 20px;
}

.footer-copyright-text {
  font-size: 14px;
  color: #1c1c1c;
  float: left;
  line-height: 25px;
}

.footer-copyright-payment {
  float: right;
}

/* BUTTONS */

.primary-btn {
  display: inline-block;
  font-size: 14px;
  padding: 10px 28px 10px;
  color: black;
  text-transform: uppercase;
  font-weight: 700;
  background: #fdcc24;
  letter-spacing: 2px;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
  margin-bottom: 50px;
  text-align: center;
}

.section-title h2 {
  color: #1c1c1c;
  font-weight: 700;
  position: relative;
}

.spad {
  padding-top: 70px;
  padding-bottom: 70px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

.section-title h2:after {
  position: absolute;
  left: 0;
  bottom: -15px;
  right: 0;
  height: 4px;
  width: 80px;
  background: #fdcc24;
  content: '';
  margin: 0 auto;
}

.load-view {
  width: 1000px;
  margin-left: 10%;
}

.alert-danger {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 1010px;
}

.btnFeatured {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  color: gray;
  margin: 0 1rem;
}

.btnFeatured:hover {
  color: black;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: black;
}

.alert-empty {
  padding: 6rem;
}


/* // Large devices (desktops, less than 1200px) */
@media (min-width: 1900px) and (max-width: 2000px) { 
  .footer{
    margin: 196px 0 0 0;
  }
}
